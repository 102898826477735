.react-bootstrap-table {
    display: block;
    width: 100%;
    overflow-y: auto;
    /*overflow-x: auto;*/
    /*max-height: 500px*/
    max-height: 100%;
}

.react-bootstrap-table-pagination {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-top: 10px;
}

.react-bootstrap-table-pagination .col-md-6 {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 50%;
}

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list .pagination {
    float: right;
}
.react-bootstrap-table-pagination button{
    line-height: 0;
}
.react-bootstrap-table-pagination button,
.react-bootstrap-table-pagination a{
    height: 29px;
    padding: 5px 10px;
}
.react-bootstrap-table-pagination div,
.react-bootstrap-table-pagination button,
.react-bootstrap-table-pagination ul {
    font-size: 0.75rem;
}

.table {
    border-top: 2px solid rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    margin: 0 !important;
}
.table th{
    height: 29px !important;
    max-height: 29px !important;
}
.table td{
    height: 24px !important;
    max-height: 24px !important;
}
.table th,
.table td {
    padding: 4px 5px !important;
    vertical-align: middle !important;
    /* white-space: nowrap; */
}

.table thead th {
    border-bottom: 1px solid #dee2e6;
    white-space: nowrap;
}

.table thead th .MuiSvgIcon-root {
    height: 16px !important;
}

.table tbody td .MuiSvgIcon-root {
    height: 18px !important;
}

thead {
    font-family: 'Helvetica', 'Segoe UI';
}

.topic-table {
    font-size: 20px;
    line-height: 36px;
    font-weight: bold;
}

.th-table {
    font-size: 20px;
    font-weight: bold;
}

.tr-table {
    color: #003366;
    font-size: 18px;
    font-weight: normal;
}

tr .table-checkbox {
    margin-top: 0;
    margin-left: 0;
}

#pageDropDown span .caret {
    border: 0;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a {
    display: block;
    padding: 3px 20px;
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item {
    padding: 0;
}

.react-bootstrap-table th {
    position: sticky;
    top: 0;
    background-color: #fff;
}

.table th {
    box-shadow: 0 1px #dee2e6, 0 -1px #dee2e6;
    /*1st for bottom, 2nd for top*/
}

.searchInput {
    height: 39px !important;
    border-radius: 4px !important;
}

.searchInput:hover {
    border-color: rgb(33 33 33);
}

.search-label {
    margin-right: 10px;
}

.headerTable {
    display: flex;
    flex-flow: unset !important;
}

@media (min-width: 960px) {
    .headerTable {
        display: flex;
        justify-content: flex-end;
    }
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu {
    top: auto !important;
    bottom: 100%;
}
.searchStyle label{
    width: 100%;
    padding-right: 3px;
}
.Headertable{
    justify-content: flex-end;
}
.Headertable .btnList {
    display: flex;
    flex-wrap: wrap;
}
.Headertable .btnList .btnstyle{
    margin-bottom: 5px !important;
}
/*.table-responsive {
    overflow-y: auto;
    max-height: 500px;
}*/


/*Report*/


/*.report-div {
    margin: 0 auto;
    max-width: 840px;
    max-height: 900px;
    padding: 3px;
}*/


/*.report-border-div {
    border: 1px solid !important;
    margin-bottom: 50px;
}*/


/*.table-report tbody tr th, .table-report tbody tr td {
    border: 1px solid !important;
}*/


/*.text-fontcenter-report {
    text-align: center;
    font-size: 20px;
}

.text-center-report {
    text-align: center;
}

.table-report {
    border-spacing: 3px;
    border-collapse: separate;
    margin-bottom: unset !important;
}*/

.table>:not(caption)>*>* {
    background-color: unset !important;
}