/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  font-family: 'Montserrat-Regular','Prompt-Light';
  font-size: 14px;
}
html, body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
} */
@font-face {
  font-family: 'Sarabun-Bold';
  src: url('assets/fonts/Sarabun-Bold.ttf');
  src: local('Sarabun-Bold'),url('assets/fonts/Sarabun-Bold.ttf') format('truetype');
  /* font-weight: 400; */
}
@font-face {
  font-family: 'Sarabun-Regular';
  src: url('assets/fonts/Sarabun-Regular.ttf');
  src: local('Sarabun-Regular'),url('assets/fonts/Sarabun-Regular.ttf') format('truetype');
  /* font-weight: 400; */
}
/* @font-face {
  font-family: 'Sarabun-ExtraBold';
  src: url('assets/fonts/Sarabun-ExtraBold.ttf');
  src: local('Sarabun-ExtraBold'),url('assets/fonts/Sarabun-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Sarabun-SemiBold';
  src: url('assets/fonts/Sarabun-SemiBold.ttf');
  src: local('Sarabun-SemiBold'),url('assets/fonts/Sarabun-SemiBold.ttf') format('truetype');
} */

body {
  font-family: 'Sarabun-Bold','Sarabun-Regular' !important;
  font-size: 14px;
}