.banner-responsive {
    width: 100%;
    height: auto;
    max-height: 200px;
  }

.dashBoardMenuHeadGroup{
  border-bottom: 2px solid #000000;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}

.dashBoardGridMenu{
  margin: 15px 0 0 0 !important;
  padding: 0 8px !important;
}