/* @media (min-width: 1366px) {
    .container {
        max-width: 100%;
    }
} */

/* .container {
    max-width: 100%;
} */

.form-control {
  /* border-radius: 0 !important; */
  padding: 6px !important;
  font-size: 14px;
  line-height: 18px;
}

.border-solid {
  border-top: 2px solid #aaaaaa;
  border-bottom: 2px solid #aaaaaa;
}

.clear-all-button {
  font-weight: bold;
  color: red;
  font-style: italic;
}

.row-HeadDetail-Modal {
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}

.text-right {
  float: right;
}

.Label-font {
  font-size: 14px;
  font-weight: bold;
}

.detail-head {
  border-bottom: 1px solid #000000;
}

.detail-form-input {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 14px;
}

.detail-form-input div {
  padding-left: 0;
  padding-right: 0;
}

.detail-form-input .custom-checkbox {
  padding-left: 1.5rem !important;
}

.dropdown-toggle {
  background-color: #e0e0e0 !important;
  border: 1px solid #e0e0e0 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.dropdown-toggle:hover {
  /* background-color: rgba(26, 13, 171, 0.80); */
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: #d5d5d5;
}

.btnstyle .dropdown-toggle::after {
  margin-left: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #e0e0e0 !important;
  color: #000000 !important;
}

.dropdown-menu .dropdown-item a {
  color: #000000 !important;
}

.dropdown-menu .dropdown-item a:hover {
  color: #1a0dab !important;
}

.textlink-detail {
  font-weight: bold;
  color: #1a0dab;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.text-detail {
  font-weight: bold;
}

.detail-form .col-detailForm div {
  padding-left: 0px;
  padding-right: 0px;
}

label {
  margin-bottom: 0;
}

.body-page {
  padding-top: 55px;
}

.form-group {
  margin-bottom: 0;
}

/*Card*/

.card-br {
  margin-bottom: 15px;
}

.card-modal {
  margin-bottom: 15px;
  max-width: 780px;
  margin: auto;
}

.card-subtitle {
  line-height: 1.5em;
  height: 3em;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title {
  font-family: "Montserrat-Bold", "Prompt-Bold";
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 5px;
}

.card-menubox-shadow {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  /*border-radius: 0 !important;
    box-shadow: 6px 6px rgba(0, 0, 0,0.16);*/
}

.card-body {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 0.5rem 1.25rem;
}

.criteria-Box-Card .card-body .cardDetailForm-Criteria {
  margin: 0;
}

.criteria-Box-Card
  .card-body
  .cardDetailForm-Criteria
  .criteria-col
  .criteria-div
  .form-group {
  margin-right: 0;
  margin-left: 0;
}

/*End Card*/

/*.report-page {
    padding-top: 40px;
    padding-bottom: 55px;
}*/

/*.job-page {
    padding-top: 95px;
}*/

/*.serive-page {
    padding-top: 55px;
}*/

/*.box-header {
    font-size: 26px;
    font-weight: bold;
}*/

span.ingredient {
  color: #f00;
}

.col-label-normal {
  font-weight: normal;
}

.col-label-bold {
  font-weight: bold;
}

.col-underline {
  border-bottom: 1px solid;
}

.div-bottom {
  margin-bottom: 20px;
}

.box-form {
  margin-bottom: 15px;
}

.internalServiceReport .card-body .detail-form {
  border-top: 1px solid #eeeeee;
}

.jobActualResult .card-body .detail-form {
  border-top: 1px solid #eeeeee;
}

.btn-transparent {
  text-decoration: none;
  padding: 0 5px;
  border: #1a0dab;
  background-color: Transparent;
}

.icon-float-right {
  float: right;
}

.icon-float-left {
  float: left;
}

.icon-arrow {
  float: right;
  border: none !important;
  font-size: 20px;
  color: rgba(13, 13, 219, 0.911);
  font-weight: bold;
}

.btnBoxResult {
  text-align: right;
  font-size: 25px;
}

/* .btn-Temp {
    border: 1px solid #1a0dab !important;
    background-color: #1a0dab !important;
    font-size: 14px !important;
    line-height: 18px;
    color: #ffffff;
    height: 31px !important;
    font-weight: bold;
    margin: 0 5px;
}

.btn-Temp:hover {
    background-color: rgba(26, 13, 171, 0.80) !important;
    color: #ffffff !important;
} */

.react-bootstrap-table-pagination-list
  .react-bootstrap-table-page-btns-ul
  .page-item
  .page-link {
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.react-bootstrap-table-pagination-list
  .react-bootstrap-table-page-btns-ul
  .page-item
  .page-link:hover {
  /* background-color: rgba(26, 13, 171, 0.80); */
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: #d5d5d5;
}

.btn-Export,
.btnDialog button {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
  height: 29px;
  padding: 0 0.75rem !important;
  font-size: 0.875rem !important;
  width: 115px;
  font-weight: 500 !important;
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.btn-Export:hover,
.btnDialog button:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
/* Signature */

.SignatureCanvas {
  border: 1px solid black;
  width: 100%;
  height: 100%;
}

/* 
    confirm alert   
*/

.swal2-title {
  font-size: 20px !important;
  line-height: 18px;
}

.swal2-popup {
  width: 25em !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  height: 29px;
  width: 115px;
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  font-size: 14px !important;
  border: none;
  font-weight: bold;
  align-items: center;
  margin: 0 5px;
  padding: 0;
}

.swal2-styled.swal2-confirm:hover,
.swal2-styled.swal2-cancel:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: #d5d5d5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

/*MainModal*/

.modal-dialog .modal-content form .modal-header .modal-title {
  font-weight: bold;
}

.modal-header {
  padding: 8px 20px 5px 20px;
}

.selectDisableCaret {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

/* input[type='file'] {
    color: transparent;
} */

.arrowDropIcon {
  color: transparent;
}

.MuiSvgIcon-root {
  cursor: pointer;
  height: 23px !important;
}

.MuiButton-root {
  letter-spacing: 0 !important;
  text-transform: none !important;
}

.MuiButton-label {
  font-weight: 500;
}

.btnstyle {
  padding: 0 !important;
  margin: 0 5px !important;
  white-space: nowrap;
  height: 29px;
}

.btnstyle button {
  height: 29px;
  width: 115px;
}
.btnstyle .btn-large {
  height: 29px;
  width: 160px;
  margin-left: 10px;
}
.MuiDialog-scrollPaper .MuiDialogContent-dividers {
  padding-top: 5px;
}

.btnstyle .MuiBox-root .MuiFormControlLabel-root {
  margin-bottom: 0;
  margin-top: 2px;
  margin-left: -5px;
}
.btnstyle button {
  line-height: 1.5 !important;
}

.btnstyle .MuiBox-root .MuiFormControlLabel-root span {
  padding: 0;
  margin-left: -1px;
}

.baseContainerStyle .MuiInputBase-root {
  padding: 0 !important;
}

.baseContainerStyle .MuiOutlinedInput-input {
  padding: 10px !important;
}

/* .baseContainerStyle .MuiIconButton-root:hover {
    background-color: unset !important;
 } */

.MuiIconButton-root:hover {
  background-color: unset !important;
}
.baseContainerStyle .criteriaStyle .boxCriteria {
  padding: 8px 8px 8px 0;
}
.dropDownStyle {
  padding: 0 !important;
}
.dropDownStyle .MuiInputLabel-marginDense {
  transform: translate(14px, 12px) scale(1);
}
.dropDownStyle .MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}

/* .makeStyles-Container-8 {
    max-width: 100% !important;
} */

.checkboxBox {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  min-height: 39px;
  padding: 0 !important;
  margin-bottom: 5px;
}
.checkboxBox:hover,
.criteriaStyle .criteriaRadioStyle:hover {
  border-color: rgb(33 33 33);
}

.checkboxBox .checkboxHeader {
  left: 10;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.54);
}

.checkboxBox .checkboxHeader span {
  position: absolute;
  white-space: nowrap;
  background-color: #ffffff;
  margin: -12px 8px 0 8px !important;
  padding: 0 5px;
}
.checkboxBox .checkboxHeader h6 {
  position: relative;
  white-space: nowrap;
  background-color: #ffffff;
  margin: -8px 8px 0 8px !important;
  padding: 0 5px;
}

.checkboxBox .checkboxList {
  padding: 5px 20px 0 20px !important;
}

.checkboxBox .checkboxList label {
  margin-bottom: 0 !important;
  flex: 0 0 30%;
  white-space: nowrap;
  width: 30%;
}

.criteriaStyle {
  margin: 0 0 5px 5px;
}

.criteriaStyle .criteriaRadioStyle {
  padding: 0 !important;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 10px;
}
.criteriaStyle .criteriaRadioStyle .boxDate {
  padding: 0 3px !important;
}
.criteriaStyle .criteriaRadioStyle .boxDate .MuiBox-root {
  padding: 3px 3px 8px 3px !important;
}

.criteriaStyle .criteriaCheckboxStyle {
  padding: 0 8px 5px 0 !important;
  margin-left: 0;
}

.criteriaStyle .criteriaRadioStyle .MuiFormControlLabel-root {
  margin-bottom: 0 !important;
}

@media (min-width: 1184px) {
  .criteriaStyle .criteriaCheckboxStyle {
    display: flex;
    justify-content: flex-end;
  }
}

.card-SystemConfig {
  max-height: 40px !important;
}
.box-input {
  margin: 0 24px 10px !important;
}
/* .baseContainerStyle .MuiButtonBase-root:enabled{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    height: 29px;
    white-space: nowrap;
    padding: 0 0.75rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    background-color: #e0e0e0 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    line-height: 1.5 !important;
} 

.baseContainerStyle .MuiButtonBase-root:enabled:hover{
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}  */
.update-field {
  margin: 0 24px;
}
.update-field fieldset {
  border-radius: 4px 0 0 4px !important;
}
.update-field button {
  border-radius: 0 4px 4px 0 !important;
  border: 1px solid rgb(189 189 189) !important;
  box-shadow: none;
  min-height: 40px;
  min-width: 64px;
}
.lineLoading {
  position: fixed;
  z-index: 999;
  top: 64px;
  left: 0;
  right: 0;
}

.body {
  height: max-content;
  margin-bottom: 50px;
}
.boxTableDetail {
  padding: 0 8px 0 0 !important;
}
.tableDetail {
  margin: 5px;
}
.tableDetail tbody tr td {
  border-bottom: 0;
  padding: 0;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-weight: 400;
  vertical-align: bottom;
  color: #212529;
}
.tableDetail tbody tr td p {
  color: #212529;
  line-height: 1.25;
  font-size: 14px;
}

.modalBoxTableDetail .tableDetail tbody tr td p {
  line-height: 1.6;
}
.tableDetail tbody tr .tdHeaderRow p {
  font-weight: bold;
}
.headerModalPlanDetail {
  background-color: #d5f0fa;
}
.headerModalJobHistoryDetail {
  background-color: #fbe9fb;
}
.headerModalPlanHistoryDetail {
  background-color: #def0de;
}
.headerModalReprocessHistoryDetail {
  background-color: #f9ede5;
}
.headerModalDependencyDetail {
  background-color: #fdfdcf;
}
.MuiDialogContent-root {
  position: relative;
}

.groupBoxButton {
  height: 44px;
  border: 1px solid #bdbdbd !important;
  border-left: 0 !important;
  box-shadow: none !important;
  border-radius: 0 4px 4px 0 !important;
}
.groupBoxInput .MuiInputBase-root {
  border-radius: 4px 0 0 4px;
}
